import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Home from "../pages/Home";
import ProductDetails from "../pages/ProductDetails";
import Pizza from "../pages/Pizza";
import Soup from '../pages/Soup';
import Pasta from '../pages/Pasta';
import Appetizer from '../pages/Appetizer';
import Salad from '../pages/Salad';

const Routers = () => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Navigate to="home" />} />
      <Route path="home" element={<Home />} />
      <Route path="pizza" element={<Pizza />} />
      <Route path="soup" element={<Soup />} />
      <Route path="salad" element={<Salad />} />
      <Route path="pasta" element={<Pasta />} />
      <Route path="appetizer" element={<Appetizer />} />
      <Route path="shop/:id" element={<ProductDetails />} />
    </Routes>
  );
};

export default Routers;
