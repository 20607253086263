import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

import { Col } from "reactstrap";

import lariImg from '../../assets/images/lari.png';
import '../../styles/product-card.css'

const ProductCard = ({ item }) => {
  const navigate = useNavigate();

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const link = () => {
    scrollTop();
    navigate(`/shop/${item.id}`);
  };

  return (
    <Col lg="3" md="4" sm='6' className="mb-2 d-flex justify-content-center">
      <div className='product__item'>
        <div className="product__img">
          <motion.img
            whileHover={{ scale: 0.94 }}
            src={item.imgUrl}
            onClick={link}
          />
        </div>
        <div className="info">
          <div className="product__info ">
            <h3 className="product__name" onClick={scrollTop}>
              <Link to={`/shop/${item.id}`}>{item.productName}</Link>
            </h3>
            <span>{item.category}</span>
          </div>
          <div className="product__card-bottom d-flex align-items-center justify-content-between p-2">
            <span className="price">
              <img src={lariImg} /> {item.price}
            </span>
            {item.type && <span>{item.type}</span>}
            
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductCard;
