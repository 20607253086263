import React from "react";
import { useParams } from "react-router-dom";
import products from "../assets/data/products";
import Helmet from "../components/Helmet/Helmet";

import { Container, Row, Col } from "reactstrap";

import lariImg from "../assets/images/lari.png";

import "../styles/product-details.css";

const ProductDetails = () => {
  const { id } = useParams();

  const product = products.find((item) => item.id === id);
  const { imgUrl, productName, category, type, geoName, price, ingredients } =
    product;

  return (
    <Helmet title={productName}>
      <section className="main-detail pt-2">
        <Container >
          <Row className="d-flex align-items-center">
            <Col lg="12" md="12">
              <div className="product-image text-center">
                <img src={imgUrl} alt={productName} />
              </div>
            </Col>
            <Col lg="12" md="12" className="product-info">
              <div className="products__details">
                <h2>{productName}</h2>
                <p>{geoName}</p>

                <div className=" d-flex align-items-center justify-content-center gap-5 ">
                  <p className="product__details-price">
                    <img src={lariImg} alt="" />
                    { " " +price}
                  </p>
                  <span>
                    <b>{type}</b> {category}
                  </span>
                </div>
                  <p>{ingredients}</p>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ProductDetails;
