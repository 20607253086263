import motsarela from "../images/pizza/motsarela.jpg";
import peperoni from "../images/pizza/peperoni.jpg";
import brusketa from "../images/appetizer/brusketa.jpg";
import mushroom_creamSoup from "../images/soup/mushroom-cream-soup.jpg";
import greek_salad from "../images/salad/greek-salad.jpg";
import lasagna from "../images/pasta/lasagna.jpg";

const products = [
  {
    id: "01",
    productName: "Motsarela",
    geoName: "მოცარელა",
    imgUrl: motsarela,
    category: "pizza",
    type: "33cm",
    price: 18,
    ingredients: "ტომატის სოუსი, ყველი ედამერი,პომიდორი, მოცარელა, პესტოს სოუსი",
  },
  {
    id: "02",
    productName: "Motsarela",
    geoName: "მოცარელა",
    imgUrl: motsarela,
    category: "pizza",
    type: "40cm",
    price: 26,
    ingredients: "ტომატის სოუსი, ყველი ედამერი,პომიდორი, მოცარელა, პესტოს სოუსი",
  },
 
  {
    id: "03",
    productName: "Peperoni",
    geoName: "პეპერონი",
    imgUrl: peperoni,
    category: "pizza",
    type: "33cm",
    price: 18,
    ingredients: "ტომატის სოუსი, ყველი ედამერი, პეპერონი",
  },
  {
    id: "04",
    productName: "Peperoni",
    geoName: "პეპერონი",
    imgUrl: peperoni,
    category: "pizza",
    type: "40cm",
    price: 25,
    ingredients: "ტომატის სოუსი, ყველი ედამერი, პეპერონი",
  },
  

  {
    id: "05",
    productName: "Brusketa",
    geoName: "ბრუსკეტა",
    imgUrl: brusketa,
    category: "appetizer",
    type: null,
    price: 8,
    ingredients: "",
  },
  {
    id: "06",
    productName: "Mushroom cream-soup",
    geoName: "სოკოს კრემსუპი",
    imgUrl: mushroom_creamSoup,
    category: "soup",
    type: null,
    price: 13,
    ingredients: "",
  },
  {
    id: "07",
    productName: "Greek salad",
    geoName: "ბერძნული სალათი",
    imgUrl: greek_salad,
    category: "salad",
    type: null,
    price: 17,
    ingredients: "",
  },
  {
    id: "08",
    productName: "Lasagna",
    geoName: "ლაზანია",
    imgUrl: lasagna,
    category: "pasta",
    type: null,
    price: 15,
    ingredients: "ლაზანიას ფირფიტები, ბოლონეზეს სოუსი, პარმეზანი, მოცარელა",
  },


];


export default products;