import React,{useEffect} from "react";
import Helmet from "../components/Helmet/Helmet";
import "../styles/home.css";

import { Container, Row, Col } from "reactstrap";
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

import pizzaImg from "../assets/images/pizza.jpg";
import pastaImg from "../assets/images/pasta.jpg";
import appetizerImg from "../assets/images/appetizer.jpg";
import saladImg from "../assets/images/salad.jpg";
import soupImg from "../assets/images/soup.jpg";

const Home = () => {
    const navigate = useNavigate();

    const moveToLink = (product)=>{
      navigate(`/${product}`);
    }

  return (
    <Helmet title="Menu">
      <section className="home">
        <Container >
          <Row>
            <Col lg="12" md="12" className="products" onClick={()=>moveToLink('pizza')}>
              <motion.div className="product-details" >
                <motion.img src={pizzaImg} alt="" whileHover={{scale:1.043}}/>
                <h3>Pizza</h3>
              </motion.div>
            </Col>

            <Col lg="12" md="12" className="products" onClick={()=>moveToLink('appetizer')}>
              <motion.div className="product-details" >
                <motion.img src={appetizerImg} alt="" whileHover={{scale:1.043}}/>
                <h3>Appetizer</h3>
              </motion.div>
            </Col>

            <Col lg="12" md="12" className="products" onClick={()=>moveToLink('pasta')}>
              <motion.div className="product-details" >
                <motion.img src={pastaImg} alt="" whileHover={{scale:1.043}}/>
                <h3>Pasta</h3>
              </motion.div>
            </Col>

            <Col lg="12" md="12" className="products" onClick={()=>moveToLink('salad')}>
              <motion.div className="product-details" >
                <motion.img src={saladImg} alt="" whileHover={{scale:1.043}}/>
                <h3>Salad</h3>
              </motion.div>
            </Col>

            <Col lg="12" md="12" className="products" onClick={()=>moveToLink('soup')}>
              <motion.div className="product-details" >
                <motion.img src={soupImg} alt="" whileHover={{scale:1.043}}/>
                <h3>Soup</h3>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
