import React from "react";
import { Container, Row, Col } from "reactstrap";
import ProductsList from "../components/UI/ProductsList";
import products from "../assets/data/products";
import Helmet from "../components/Helmet/Helmet";

import "../styles/filter-item.css";

const Soup = () => {
  const filteredProducts = products.filter((item) => item.category === "soup");

  return (
    <Helmet title="Soup">
      <section className="filtered__items">
        <Container>
          <Row>
            <Col lg="12">
              <h2 className="filtered__items-title text-center mt-5 mb-5">Soup</h2>
            </Col>
            <ProductsList data={filteredProducts} />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Soup;
